<template>
  <div class="phone-verify-content">
    <div class="info">
      <div class="age">19</div>
      <div class="content">
        <h2>{{ $t('phone.str_adult_auth_title') }}</h2>
        <p>
          {{ $t('phone.str_adult_auth_text1_1') }}
        </p>
        <p>
          {{ $t('phone.str_adult_auth_text1_2') }}
        </p>
        <div class="btn-group">
          <button @click="onVerify">{{ $t('phone.verify') }}</button>
          <button @click="onExit">{{ $t('phone.exit') }}</button>
        </div>
      </div>
    </div>
    <div class="desc">
      - {{ $t('phone.str_adult_auth_text3_1') }}<br/>
      - {{ $t('phone.str_adult_auth_text3_2') }}<br/>
      - {{ $t('phone.str_adult_auth_text3_3') }}<br/>
    </div>
  </div>
</template>

<script>

import { requestPost, showToast } from "../../lib/util";

export default {
  name: 'PhoneVerify',
  components: {},
  data() {
    return {
      myInfo: this.$store.getters.me,
    };
  },
  methods: {
    onVerify() {
      requestPost(
        'nice/getNiceEncData',
        {
          uid: this.myInfo.uid
        },
        response => {
          window.addEventListener('message', this.onVerifyResult, false);

          var niceAuthForm = document.createElement("form");
          niceAuthForm.target = "niceAuth";
          niceAuthForm.method = "POST";
          niceAuthForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";

          var mInput = document.createElement("input");
          mInput.type = "hidden";
          mInput.name = "m";
          mInput.value = "checkplusSerivce";
          niceAuthForm.appendChild(mInput);

          var encDataInput = document.createElement("input");
          encDataInput.type = "hidden";
          encDataInput.name = "EncodeData";
          encDataInput.value = response.enc_data;
          niceAuthForm.appendChild(encDataInput);

          document.body.appendChild(niceAuthForm);

          let niceAuthWindow = window.open("", "niceAuth");

          if (niceAuthWindow) {
            niceAuthForm.submit();
            document.body.removeChild(niceAuthForm);
          }
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    onExit() {
      window.close();
    },

    onVerifyResult(event) {
      if (event.origin !== 'https://api.camtalkaws.com:38750') {
        return;
      }

      if (event.data === 'success') {
        this.$root.onTab(0);
      } else {
        showToast('인증이 실패하였습니다.');
      }
    },
  }
}
</script>

<style lang="scss" scoped>

body {
  margin: 0;
}

div:not(.carousel__viewport) {
  display: flex;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

.phone-verify-content {
  background-color: white;
  width: 1100px;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  .info {
    margin-top: 105px;
    padding: 0 32px;
    align-items: center;

    @media (max-width: 768px) {
      margin-top: 58px;
      padding: 0 44px;
      flex-direction: column;
      align-items: inherit;
    }

    .age {
      width: 297px;
      height: 297px;
      border: 15px solid #f25c62;
      border-radius: 149px;
      color: #000000;
      font-size: 130px;
      font-weight: bold;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: 114px;
        height: 114px;
        border-radius: 57px;
        border-width: 6px;
        font-size: 54px;
        align-self: center;
      }
    }

    .content {
      flex: 1;
      flex-direction: column;
      margin-left: 100px;

      @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 32px;

        h2, p {
          padding: 0 4px;
        }
      }

      h2 {
        color: #f25c62;
        font-weight: bold;
        font-size: 45px;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }

      p {
        margin-top: 60px;
        font-size: 28px;

        @media (max-width: 768px) {
          margin-top: 28px;
          font-size: 14px;
        }
      }

      h2 + p {
        color: #000000;
      }

      p + p {
        color: #f25c62;
        font-weight: bold;
      }

      .btn-group {
        height: 85px;
        margin-top: 50px;

        @media (max-width: 768px) {
          height: 40px;
          margin-top: 36px;
        }

        button {
          flex: 1;
          height: 100%;
          outline: none;
          font-size: 36px;

          @media (max-width: 768px) {
            font-size: 18px;
          }
        }

        > button {
          background-color: #f25c62;
          color: #ffffff;
        }

        button + button {
          background-color: #ffffff;
          color: #979797;
          border: 4px solid #808080;
          margin-left: 16px;

          @media (max-width: 768px) {
            margin-left: 7px;
            border: 2px solid #acacac;
          }
        }
      }
    }
  }

  .desc {
    margin-top: 120px;
    padding: 0 45px;
    font-size: 20px;

    @media (max-width: 768px) {
      margin-top: 26px;
      padding: 0 40px;
      font-size: 9px;
      color: #404040;
    }
  }
}
</style>
